import { Box, Text, Button } from 'rebass';

export function Lessons({lesson, lesson_id_key}) {
    return (
        <Box mx="auto"
        className="lesson-container"
        sx={{
            border: '1px solid #ccc!important',
            borderRadius: '10px',
            padding: '5px',
            marginBottom: '8px',
            display: 'grid',
            gridGap: 4,
            gridTemplateColumns: 'repeat(auto-fit, minmax(128px, 1fr))'
        }}>
            <Box className="enter-card-content">
                <h4 className="lesson-header">{lesson.name}</h4>
                <Text fontSize={[ 1 ]} width='100%'>{lesson.description}</Text>
            </Box>
            <Box className="enter-button">
                <Button onClick={() => {
                    var lesson_url = window.location.href.replace(/\/$/, "");;
                    window.location.href = lesson_url + "/lesson/" + lesson.id;
                }} className="red-button"><span>Enter </span></Button>
            </Box>
        </Box>
    )
}