import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from './App';
import AudioRecorder from 'audio-recorder-polyfill';
import { Loader } from "./Loader";
import axios from "axios";
import { sortBy } from 'lodash';
import { Lessons } from './Lessons';
import { Button } from 'rebass';
import {
    HashRouter as Router,
    Switch,
    Route,
    useParams,
    Redirect
  } from "react-router-dom";

window.MediaRecorder = AudioRecorder

const api_host_url = "https://app.pitchperfect.training/";
// const api_host_url = "http://127.0.0.1:5000/";
const lesson_id_key = "pitchdetectorapp_lesson_id";
const static_lesson_id = window.lessonId === "{{LESSON_ID}}" ? null : window.lessonId;

const loaderState = {
    isTransparent: false,
    show: true
}

ReactDOM.render(
    <>
        <Router>
            <Switch>
                <Route exact path="/" component={
                    () => static_lesson_id ? 
                    <LessonAppStandalone lessonId={static_lesson_id} /> :
                    <LessonList />
                } />

                <Route path="/lesson/:id">
                    <LessonApp />
                </Route>
            </Switch>
        </Router>
    </>,
    document.getElementById("root")
);


function LessonList() {
    const [lesson_objects, setLessonList]= useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        axios.get(`${api_host_url}get_lessons`)
        .then (res => {
            if (res.data && res.data['lessons']) {
                const lessons = sortBy(res.data['lessons'], ['order']);
                const lesson_components = [];

                lessons.forEach((item, index) => {
                    lesson_components.push(<Lessons key={index} lesson={item} lesson_id_key={lesson_id_key} />)
                })

                setLessonList(lesson_components);
                setLoading(false);
            }
        });
    }, []);

    if (loading) {
        return <Loader />;
    } else {
        return <div style={{marginTop: "25px", marginBottom: "30px"}}>{lesson_objects}</div>;
    }
}

function LessonApp() {
    const [components, setComponents] = useState(<></>);
    const [loading, setLoading] = useState(true);
    let { id } = useParams();

    useEffect(() => {
        axios.get(`${api_host_url}get_words?lesson_id=${id}`)
        .then (res => {
            if (res.data && res.data['words']) {
                setComponents(<>
                    <App word_data={sortBy(res.data['words'], ['order'])} api_host_url={api_host_url} is_standalone={false} />
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <Button onClick={() => {
                             window.location.href = "/lessons";
                             loaderState.show = true;
                        }} className="blue-button" mt={3}><span> Back To Lessons</span></Button>
                    </div>
                </>);
            }
        });

        setNextLesson(id, setLoading);
    }, []);

    if (loading) {
        return <Loader />;
    } else {
        return (components);
    }
}

function LessonAppStandalone({ lessonId }) {
    const [components, setComponents] = useState(<></>);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${api_host_url}get_words?lesson_id=${lessonId}`)
        .then (res => {
            if (res.data && res.data['words']) {
                setComponents(<>
                    <App word_data={sortBy(res.data['words'], ['order'])} api_host_url={api_host_url} is_standalone={true} />
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <Button onClick={() => {
                             window.location.href = "/lessons";
                             loaderState.show = true;
                        }} className="blue-button" mt={3}><span> Back To Lessons</span></Button>
                    </div>
                </>);
            }
        });

        setNextLesson(window.lessonId, setLoading);
    }, []);

    if (loading) {
        return <Loader />;
    } else {
        return (components);
    }
}

function setNextLesson(current_lession_id , setLoadingState) {
    axios.get(`${api_host_url}get_lesson_navigation?current=${current_lession_id}`)
    .then(res => {
        const lesson_navigation = {
            next_lesson_url: window.location.href.replace(current_lession_id, res.data.next_lesson.lessons[0].id),
            next_lesson_order: res.data.next_lesson.lessons[0].order,
            prev_lesson_url: window.location.href.replace(current_lession_id, res.data.prev_lesson.lessons[0].id),
            prev_lesson_order: res.data.next_lesson.lessons[0].order
        }

        window.localStorage.setItem("lesson_navigation", JSON.stringify(res.data));

        setLoadingState(false);
    });
}