export function Loader() {
    return <>
        <div id="loader" className="loader-container">
            <div className="loadingio-spinner-ellipsis-d4plxa73ihf">
                <div className="ldio-ulnb0il8ln">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </>
}