import React from 'react';
import { useParams } from "react-router-dom";
import { Button, Box } from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faPause } from '@fortawesome/free-solid-svg-icons'
import { faStop } from '@fortawesome/free-solid-svg-icons'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faForward } from '@fortawesome/free-solid-svg-icons'
import { faBackward } from '@fortawesome/free-solid-svg-icons'

function Control({ onClick, color, icon, children }) {
    const style = {
        cursor: 'pointer',
        '@media screen and (max-width: 40em)': {
            padding: '12px'
        }
    };

    return (
        <Button onClick={onClick} className="control" variant="primary" bg="transparent" sx={style} >
            <Box color={color}>
                <FontAwesomeIcon icon={icon} />
            </Box>
            <Box color="black" className="control-txt" sx={{
                width: ["40px", "55px"],
                fontSize: ["14px", "16px"],
            }}>{children}</Box>
        </Button>
    )
}

function Record({ mode, start, stop }) {
    if (!(mode === "recording")) {
        return <Control onClick={start} color="red" icon={faCircle}>Record</Control>

    } else {
        return <Control onClick={stop} color="red" icon={faStop}>Stop</Control>
    }
}

function PlayNative({words, mode, playNative, playNativeIcon, cursor, gender, host }) {
    const gender_lowercase = gender.toLowerCase();
    const word = words[cursor];
    const filename = word["file_" + gender_lowercase];

    if(playNativeIcon) {
        return <Control onClick={playNative} color="red" color="green" icon={playNativeIcon}>
            Native
            <audio id="playNative" src={`${host}audio_lessons/${gender_lowercase}/${filename}`} crossOrigin="anonymous"></audio>
        </Control>
    }
    
    if (mode === "playingNative") {
        return <Control onClick={playNative} color="red" color="green" icon={faPause}>
            Native
            <audio id="playNative" src={`${host}audio_lessons/${gender_lowercase}/${filename}`} crossOrigin="anonymous"></audio>
        </Control>
    }

    return <Control onClick={playNative} color="red" color="green" icon={faPlay}>
            Native
            <audio id="playNative" src={`${host}audio_lessons/${gender_lowercase}/${filename}`} crossOrigin="anonymous"></audio>
    </Control>
}

function PlayMe({mode, playMe, src }) {
    if (mode === "playingMe") {
        return <Control onClick={playMe} color="red" color="purple" icon={faPause}>
            Me
            <audio id="playMe" src={src}></audio>
        </Control>
    } else {
        return <Control onClick={playMe} color="red" color="purple" icon={faPlay}>
            Me
            <audio id="playMe" src={src}></audio>
    </Control>
    }
}

export function Controls({ start, stop, playMe, playNative, playNativeIcon, srcMe, mode, cursor, setCursor, words, gender, host, isStandalone }) {
    let { id } = useParams();
    return <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Box mx="auto" className="control-container">
            <Control onClick={() => {
                const dec = cursor - 1;
                const back = Math.max(0, dec);

                if (dec < 0) {
                    var lesson_navigation = JSON.parse(window.localStorage.getItem("lesson_navigation"));
                    let current_lesson_order = lesson_navigation.next_lesson.lessons[0].order - 1;

                    if (lesson_navigation.prev_lesson.lessons[0].order !== current_lesson_order) {
                        if (!isStandalone) {
                            window.location.href = window.location.href.replace(id, lesson_navigation.prev_lesson.lessons[0].id);
                            window.location.reload();
                        } else {
                            if (lesson_navigation.next_lesson.lessons[0].order === 0) {
                                current_lesson_order = lesson_navigation.total_lessons - 1;
                            }
                            
                            const prev_lesson = `lesson-${lesson_navigation.prev_lesson.lessons[0].order + 1}`;
                            window.location.href = window.location.href.replace(`lesson-${current_lesson_order + 1}`, prev_lesson);
                        }
                    }
                } else {
                    setCursor(back);
                    playNative();
                }
            }} color="green" icon={faBackward} >Back</Control>
            <Record mode={mode} start={start} stop={stop} />
            <PlayMe playMe={playMe} mode={mode} src={srcMe} />
            <PlayNative words={words} cursor={cursor} gender={gender} host={host} playNative={playNative} mode={mode} playNativeIcon={playNativeIcon} />
            <Control onClick={() => {
                const length = words.length - 1;
                const inc = cursor + 1;
                const next = Math.min(length, inc);

                if (inc > length) {
                    var lesson_navigation = JSON.parse(window.localStorage.getItem("lesson_navigation"));
                    if (!isStandalone) {
                        window.location.href = window.location.href.replace(id, lesson_navigation.next_lesson.lessons[0].id);
                        window.location.reload();
                    } else {
                        if (lesson_navigation.next_lesson.lessons[0].order === 0) {
                            window.location.href = window.location.href.replace(`lesson-${lesson_navigation.total_lessons}`, 'lesson-1');
                        } else {
                            const current_lesson_order = lesson_navigation.next_lesson.lessons[0].order - 1;
                            const next_lesson = `lesson-${lesson_navigation.next_lesson.lessons[0].order + 1}`;
                            window.location.href = window.location.href.replace(`lesson-${current_lesson_order + 1}`, next_lesson);
                        }
                    }
                } else {
                    setCursor(next);
                    playNative();
                }

            }} color="green" icon={faForward}>Next</Control>
        </Box>
    </div>
}
