import React from 'react';
import { Box } from 'rebass';
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis } from 'victory';

export function Chart({ width, line1, line2, range }) {
    return <Box
        mx="auto"
        className="chart-container"
    >

        <svg style={{ height: 0 }}>
            <defs>
                <linearGradient spreadMethod="pad" id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stopColor="rgb(208, 208, 217)" />
                    <stop offset="100%" stopColor="rgb(250, 203, 255)" />
                </linearGradient>
            </defs>
        </svg>

        <VictoryChart
            theme={VictoryTheme.material}
            domain={{ x: [-5, range], y: [-200, 700] }}


            style={{
                background: { fill: "url(#gradient)" }
            }}
            
            padding="0"
            height="250" 
            width="350"
        >

            {line1.map((data, index) =>
                <VictoryLine
                    style={{
                        data: { stroke: "purple" },
                        parent: { border: '1px solid #ccc' },
                    }}
                    data={data}
                    key={index}
                />)
            }
            {line2.map((data, index) =>
                <VictoryLine
                    style={{
                        data: { stroke: 'green' },
                        parent: { border: '1px solid #ccc' },
                    }}
                    data={data}
                    key={index}
                />)
            }
            <VictoryAxis style={{
                axis: { stroke: "transparent" },
                ticks: { stroke: "transparent" },
                tickLabels: { fill: "transparent" },
                grid: { stroke: "transparent" }
            }} />
        </VictoryChart>
    </Box>
}
