import React, { useState, useEffect, useCallback } from "react";
import { AudioContext } from "standardized-audio-context";
import { Box, Text, Flex } from 'rebass';
import { Label, Radio } from '@rebass/forms'
import { Chart } from "./Chart";
import { Controls } from "./Controls";
import axios from 'axios';
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { find } from "lodash";

function App({ word_data, api_host_url, is_standalone }) {
  const [mode, setMode] = useState("setup");
  const [gestured, setGestured] = useState(false);
  const [audioDeps, setAudioDeps] = useState({
    ctx: false,
    stream: false,
    microphone: false,
    echoNode: false,
  });
  const [micData, setMicData] = useState([]);
  const [nativeData, setNativeData] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(false);
  const [srcMe, setSrcMe] = useState([]);
  const [aud_blob, setAudBlob] = useState(null);
  const [cursor, setCursor] = useState(0);
  // when gender changes, need to clear graph
  const [gender, setGender] = useState('Male');
  const [playNativeIcon, setPlayNativeIcon] = useState(null);
  const [forceStopped, setForceStopped] = useState(null);
  const [plotRange, setPlotRange] = useState(100);
  const [isStandalone, setStandaloneState] = useState(is_standalone);

  // setup audio, available only after gesture
  const init = useCallback(async () => {
    if (!gestured) {
      // need user gesture prior to audio api
      setGestured(true);
      const ctx = new AudioContext();
      // microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // media recorder needed for microphone playback
      setMediaRecorder(new MediaRecorder(stream), {
        mimeType: "audio/ogg; codecs=opus",
      });
      const microphone = ctx.createMediaStreamSource(stream);
      // analyser
      const analyser = ctx.createAnalyser();
      analyser.fftSize = 1024;

      // audio players
      // prevents naming collisions inside switch cases
      const playNativeElement = document.querySelector("#playNative");
      const playMeElement = document.querySelector("#playMe");

      // build graph for native speaker
      const playNative = ctx.createMediaElementSource(
        playNativeElement
      );
      playNative.connect(analyser);
      playNative.connect(ctx.destination);

      // set state to use in effectful actions*/
      setAudioDeps({
        ctx,
        stream,
        microphone,
        analyser,
        playNativeElement,
        playNative,
        playMeElement,
      });
    }
  }, [gestured])

  // media recorder effects
  useEffect(() => {
    console.log("media recorder effects");

    if (mediaRecorder) {
      mediaRecorder.addEventListener('dataavailable', e => {
        console.log("Listen Data Available ");
        const audioURL = window.URL.createObjectURL(e.data);
        setSrcMe(audioURL);
        setAudBlob(e.data);
      });

      setMediaRecorder(mediaRecorder);
    }

  }, [mediaRecorder, cursor, gender]);

  useEffect(() => {
    if (aud_blob) {
      const fd = new FormData();
      fd.append("audio", aud_blob, "test_rec");
      fd.append("native_aud_id", word_data[cursor]['id'],);
      fd.append("native_aud_gender", gender);

      axios.post(`${api_host_url}get_pitch/me`, fd)
      .then(res => {
        if(res.data) {
          setMicData(breakLine(res.data.pitch, res.data.time, plotRange));
        }   
      });
    }

  }, [aud_blob, cursor, gender]);

  // playNativeElement handle stopped. Needs separate effect due to deps
  useEffect(() => {
    if (mode === "playNativeStopped") {
      if(nativeData.length === 0) {
        axios.get(`${api_host_url}get_pitch/native`, {
          params: {
            id: word_data[cursor]['id'],
            gender: gender
          }
        })
        .then(res => {
          setPlotRange(res.data.length >= 100 ? res.data.length : 100);
          setNativeData(breakLine(res.data, []));
        });
      }
    }

  }, [mode]);

  useEffect(() => {
    if(mode === "playingNative") {
      stopPlayingNative();    
      setPlayNativeIcon(faPlay);
      setForceStopped(true);
    }

  }, [gender]);


  useEffect(() => {
    if(mode === "playingNative") {
      stopPlayingNative();
    }

    if(mode === "playingMe") {
      stopPlayingMe();
    }

    if(mode !== "setup") {
      playNative();
    }

  }, [cursor]);

  // button effects
  useEffect(() => {
    if (mode !== "setup" && gestured) {
      switch (mode) {
        case "recording":
          if (mediaRecorder && mediaRecorder.state !== "recording") {
            audioDeps.microphone.connect(audioDeps.analyser);
            mediaRecorder.start();
          }
          break;

        case "stopped":
          if (mediaRecorder && mediaRecorder.state !== "inactive") {
            mediaRecorder.stop();
            mediaRecorder.requestData();
          }
          break;

        case "playingMe":        
          break;

        case "playingNative":
          if (mediaRecorder && mediaRecorder.state !== "inactive") {
            mediaRecorder.stop();
            mediaRecorder.requestData();
          }

          break;

        default:
          console.log("default effect");
          break;
      }
    }
  }, [mode, audioDeps, gestured, mediaRecorder]);

  async function start() {
    await init();

    if(mode === "playingNative") {
      if(!forceStopped) {
        setMode("playNativeStopped");
      }
      stopPlayingNative();
      setPlayNativeIcon(faPlay);
    }

    if(mode === "playingMe") {
      setMode("playMeStopped");
      stopPlayingMe();
    }

    setSrcMe([]);
    setMicData([]);
    setMode("recording");
  }

  async function stop() {
    // audio api needs gesture. can't run in effect, as that runs on every render.
    await init();
    setMode("stopped");
    playMe();
  }

  async function playMe() {
    const playMeElement = document.querySelector("#playMe");

    playMeElement.addEventListener(
      "ended",
      (event) => {
        event.target.pause();
        event.target.currentTime = 0;
        setMode("playMeStopped");
      }
    );

    if(mode === "recording") {
      setSrcMe([]);
      setMode("stopped");
    }

    if(mode === "playingNative") {
      setMode("playNativeStopped");
      stopPlayingNative();
      setPlayNativeIcon(faPlay);
    }
    
    if(mediaRecorder) {
      setTimeout(() => {
        setMode("playingMe");
        (document.querySelector("#playMe")).play();
      }, 100);
    }
  }


  const playNative = useCallback(async () => {
    const playNativeElement = document.querySelector("#playNative");

    playNativeElement.addEventListener(
      "ended",
      (event) => {
        event.target.pause();
        event.target.currentTime = 0;
        setMode("playNativeStopped");
        setPlayNativeIcon(faPlay);
        setForceStopped(false);
      }
    );

    if(mode === "playingMe") {
      setMode("playMeStopped");
      stopPlayingMe();
    }

    setMode("playingNative");
    await (document.querySelector("#playNative")).play();
    setPlayNativeIcon(faPause);
  })

  function stopPlayingMe() {
    const player = document.querySelector("#playMe");
    player.pause();
    player.currentTime = 0;
  }

  function stopPlayingNative() {
    const player = document.querySelector("#playNative");
    player.pause();
    player.currentTime = 0;
  }

  const word = word_data[cursor];

  return (
    <>
      <Box mx='auto' mt="1rem" className="word-box">
        <Text textAlign="center">{word.chinese}</Text>
        <Text textAlign="center">{word.english}</Text>
        <Text textAlign="center">{word.meaning}</Text>
      </Box>
      <Chart line1={micData} line2={nativeData} range={plotRange} />
      <Flex mx="auto" my="0.5rem">
        <Box width={1 / 2}>
          <Label sx={{ maxWidth: '65px', marginRight: '15px', float: 'right' }}>
            <Radio
              name='gender'
              id='male'
              value='Male'
              onChange={(e) => {
                setGender(e.currentTarget.value);
                setNativeData([]);
                setAudBlob(null);
              }}
              checked={gender === "Male"}
            />
            Male
          </Label>
        </Box>
        <Box width={1 / 2}>
          <Label>
            <Radio
              name='gender'
              id='female'
              value='Female'
              onChange={(e) => {
                setGender(e.currentTarget.value);
                setNativeData([]);
                setAudBlob(null);
              }}
              checked={gender === "Female"}
            />
            Female
          </Label>
        </Box>
      </Flex>

      <Controls
        start={start}
        stop={stop}
        playMe={playMe}
        playNative={playNative}
        srcMe={srcMe}
        mode={mode}
        cursor={cursor}
        setCursor={(cursor) => {
          setMicData([]);
          setNativeData([]);
          setAudBlob(null);
          setCursor(cursor);
        }}
        words={word_data}
        gender={gender}
        host={api_host_url}
        playNativeIcon={playNativeIcon}
        isStandalone={isStandalone}
      />
    </>
  );
}

export function getWord(words, cursor) {
  return find(words, (word) => { return word['cursor'] !== cursor; })
}

export function breakLine(data, time, plotRange) {
  const nativeData = [];

  let j = 0;
  var isNewAffay=true;
  let tempArray= [];

  for (j; j < data.length; j++)
  {
    if((j === data.length-1) && data[j] !== -1)
    {
      nativeData.push(tempArray);
      break;
    }

    if(data[j] === -1)
    {
      isNewAffay=true;

      if(tempArray.length !== 0)
      {
        nativeData.push(tempArray);
        tempArray= [];
      }

      continue;
    }

    let xVal = j;

    if(time.length > 0) {
      xVal = time[j] * plotRange;
    }

    if(isNewAffay)
    {
      tempArray= [];
      isNewAffay=false;
      tempArray.push({
        x: xVal,
        y: data[j]
      });
    }
    else
    {
      tempArray.push({
        x: xVal,
        y: data[j]
      });
    }
  }

   return nativeData;
}

export default App;
